import server from "@/utils/request.js";

export default {

    /**
     * 以下接口参数均相同
     * @param {province:null,city:null,area:null} params 
     * @returns 
     */
    //按照品牌统计
    brand: (params) => server.post(`/statistic/brand`, params),
    //电梯运行数据统计 
    runStatistic: (params) => server.post(`/statistic/elevatorStatisticData`, params),
    //物联状态 （在线，离线）
    onlineStatus: (params) => server.post(`/statistic/virtualDeviceOnlineStatus`, params),
    //绑定状态（注册，未注册）
    bindStatus: (params) => server.post(`/statistic/bindStatus`, params),
    //客户信息
    companyInfo: (params) => server.post(`/statistic/companyInfo`, params),
    //地图信息省市区小区数量
    nationalMap: (params) => server.post(`/statistic/nationalMap`, params),
    //地图hover单独省市区的电梯状态数量
    districtElvNum: (params) => server.post(`/statistic/area/elevator`, params),
}
