import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "./style/index.css";
import "./style/common.scss";

import api from "@/api/index.js";

Vue.config.productionTip = false;
Vue.prototype.$api = api;

// 全局获取缓存数据,获取全局省市区的变化
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'district') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
